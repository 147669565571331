import React from "react";
import "./App.css";
import "semantic-ui-css/semantic.min.css";
import Form from "./Form/Form";
import SuccessPage from "./Form/SuccessPage.js";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import axios from "axios";
import Loader from "react-loader-spinner";
import parse from 'html-react-parser';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            height: 0,
            main_color: "",
            second_color: "",
            id_club: "",
            backgroundLoaded: false,
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);

        // const url = "https://suporter.academiadidi.ro";
        // const team_name = url.split(".")[1];

        // const team_name = 'suporter.premieriasi.ro';
        // const team_name = 'suporter.academiadidi.ro';
        // const team_name = 'politehnicaiasi.tfb.academy';
        const team_name = window.location.hostname;
        // console.log(window.location.hostname);

        axios
            .get(
                `https://api.thefootballbrain.app/club/declaration_form_color?team_name=${team_name}`
            )
            .then((res) => {
                this.setState({
                    main_color: res.data.color,
                    second_color: res.data.second_color,
                    id_club: res.data.id_club,
                    declaration_form_description: res.data.declaration_form_description != null ?
                        parse(res.data.declaration_form_description)
                        : "",
                });
            })
            .catch((err) => console.log(err));

    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
    render() {
        return (
            <Router>
                <ScrollToTop>
                    <div className="App">
                        <div
                            className="background-image-container"
                            style={{ backgroundColor: this.state.main_color }}
                        >
                            <img
                                src={`https://api.thefootballbrain.app/uploads/tax_redirectors/form_background_image/${this.state.id_club}.png`}
                                alt="background"
                                style={
                                    this.state.backgroundLoaded
                                        ? {}
                                        : { display: "none" }
                                }
                                className="background-image"
                                onLoad={() =>
                                    this.setState({ backgroundLoaded: true })
                                }
                            />
                        </div>
                        {!this.state.backgroundLoaded ? (
                            <div className="loader-container">
                                <Loader
                                    type="TailSpin"
                                    color={this.state.main_color}
                                    height={80}
                                    width={80}
                                />
                            </div>
                        ) : (
                            <Switch>
                                <Route
                                    exact
                                    path="/"
                                    render={(props) => (
                                        <Form
                                            {...props}
                                            width={this.state.width}
                                            height={this.state.height}
                                            id_club={this.state.id_club}
                                            color={this.state.main_color}
                                            second_color={
                                                this.state.second_color
                                            }
                                            title_color={
                                                this.state.id_club == 1 ||
                                                    this.state.id_club == 4
                                                    ? this.state.main_color
                                                    : this.state.second_color
                                            }
                                            declaration_form_description={this.state.declaration_form_description}
                                        />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/success"
                                    render={(props) => (
                                        <SuccessPage
                                            {...props}
                                            color={
                                                this.state.id_club == 1 ||
                                                    this.state.id_club == 4
                                                    ? this.state.main_color
                                                    : this.state.second_color
                                            }
                                            buttonColor={this.state.main_color}
                                            buttonColorText={
                                                this.state.second_color
                                            }
                                        />
                                    )}
                                />
                            </Switch>
                        )}
                    </div>
                </ScrollToTop>
            </Router>
        );
    }
}

export default App;
